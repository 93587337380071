

const BASE_URL = `${process.env.REACT_APP_BACKEND_SERVER_URL}/api/`;
export const getAllHairApi = async () => {
  const res = await fetch(`${BASE_URL}hair/getAllHair`);
  let data = await res.json();

  return data;
};

export const getOneHair = async (hairId) => {
  const res = await fetch(`${BASE_URL}hair/getOneHair/${hairId}`);
  let data = await res.json();
  return data;
};

export const stripePaymentApi = async (formData) => {
  const data = await fetch(`${BASE_URL}hair/stripePayment`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ formData }),
  });
  return await data.json();
};

export const confirmedPaymentApi = async (formData) => {
  const data = await fetch(`${BASE_URL}hair/sendConfirmMessage`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ formData }),
  });
  return await data.json();
};

export const getHairGallery = async () => {
  const res = await fetch(`${BASE_URL}gallery/getAllGallery`);
  let data = await res.json();
  return data;
};

export const paymentIntentApi = async (formData) => {
  const data = await fetch(`${BASE_URL}hair/paymentIntent`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ items: "hello" }),
  });
  return await data.json();
};

export const checkDayAvailability = async (selectedDay) => {
  const data = await fetch(`${BASE_URL}hair/accessAllBookings`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ selectedDay }),
  });
  return await data.json();
};
