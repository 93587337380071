import * as React from "react";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";

export default function SimpleSnackbar({ openSnackBar, setOpenSnackBar }) {
  const [open, setOpen] = React.useState(false);

  const handleClick = () => {
    setOpenSnackBar({ open: true });
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackBar({ open: false });
  };

  const action = (
    <React.Fragment>
      <Button color="secondary" size="small" onClick={handleClose}>
        Close
      </Button>
      <IconButton
        size="small"
        aria-label="close"
        color="inherit"
        onClick={handleClose}
      ></IconButton>
    </React.Fragment>
  );

  return (
    <Snackbar
      open={openSnackBar.open}
      autoHideDuration={6000}
      onClose={handleClose}
      message={openSnackBar.text}
      action={action}
    />
  );
}
