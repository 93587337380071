import React, { useState } from "react";
import PoliciesModal from "./PoliciesModal";

function Policies({ policies, setPolicies }) {
  const [open, setOpen] = useState(false);
  return (
    <div className="relative flex items-start">
      <div className="flex h-6 items-center">
        <input
          id="candidates"
          aria-describedby="candidates-description"
          name="candidates"
          onClick={() => setPolicies(!policies)}
          type="checkbox"
          className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
        />
      </div>
      <div className="ml-3 text-sm leading-6">
        {/* <label htmlFor="candidates" className="font-medium text-gray-900">
          Policies
        </label>{" "} */}
        <span id="candidates-description" className="text-gray-500">
          I agree to receive
          <span
            onClick={() => setOpen(true)}
            className="text-rose-950 cursor-pointer underline md:underline-offset-4"
          >
            {" "}
            SMS{" "}
          </span>
          based on my data
        </span>
      </div>
      <PoliciesModal setOpen={setOpen} open={open} />
    </div>
  );
}

export default Policies;
