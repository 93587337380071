import { useMemo, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import {
  confirmedPaymentApi,
  stripePaymentApi,
} from "../services/HairServices";
import { useCreateSuccessfulMessageContext } from "../context/SuccessfulMessageContext";

const Success = () => {
  // const { setSuccessfulRedirect, successfulRedirect } =
  //   useCreateSuccessfulMessageContext();

  // const navigate = useNavigate();
  // console.log(successfulRedirect, "successfulRedirect in the success page");

  // function useQuery() {
  //   const { search } = useLocation();
  //   return useMemo(() => new URLSearchParams(search), [search]);
  // }
  // let query = useQuery();

  // useEffect(() => {
  //   // if (successfulRedirect === false) {
  //   //   setSuccessfulRedirect(false);
  //   //   return navigate("/");
  //   // }
  //   const getqueryParams = async () => {
  //     const firstName = query.get("firstName");
  //     const lastName = query.get("lastName");
  //     const paymentSuccess = query.get("paymentSuccess");
  //     const email = query.get("email");
  //     const hair = query.get("hair");
  //     const date = query.get("date");
  //     const time = query.get("time");
  //     const hairId = query.get("hairId");
  //     const selected = query.get("selected");
  //     const phoneNumber = query.get("phoneNumber");
  //     const finishedHairTime = query.get("finishedHairTime");
  //     const formData = {
  //       firstName,
  //       lastName,
  //       paymentSuccess,
  //       email,
  //       hair,
  //       date,
  //       time,
  //       phoneNumber,
  //       finishedHairTime,
  //       hairId,
  //       selected,
  //     };

  //     console.log({
  //       paymentSuccess,
  //       email,
  //       hair,
  //       date,
  //       time,
  //       phoneNumber,
  //       hairId,
  //       selected,
  //     });

  //     // const returnUrl = async () => {
  //     //   const bookingData = await stripePaymentApi(formData);
  //     //   if (bookingData.error) {
  //     //     console.log("Server Error");
  //     //     return;
  //     //   }
  //     //   await confirmedPaymentApi(formData);
  //     // };
  //     // await returnUrl();
  //     // setTimeout(() => {
  //     //   navigate("/");
  //     // }, 2000);
  //   };

  //   getqueryParams();
  // }, [navigate, query]);

  return (
    <div class="bg-gray-100 h-screen flex items-center justify-center">
      <div class="bg-white p-6 md:mx-auto">
        <svg viewBox="0 0 24 24" class="text-green-600 w-16 h-16 mx-auto my-6">
          <path
            fill="currentColor"
            d="M12,0A12,12,0,1,0,24,12,12.014,12.014,0,0,0,12,0Zm6.927,8.2-6.845,9.289a1.011,1.011,0,0,1-1.43.188L5.764,13.769a1,1,0,1,1,1.25-1.562l4.076,3.261,6.227-8.451A1,1,0,1,1,18.927,8.2Z"
          ></path>
        </svg>
        <div class="text-center">
          <h3 class="md:text-2xl text-base text-gray-900 font-semibold text-center">
            Payment Done!
          </h3>
          <p class="text-gray-600 my-2">
            Thank you for completing your secure online payment.
          </p>
          <p> Have a great day! </p>
        </div>
      </div>
    </div>
  );
};
export default Success;
