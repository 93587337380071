import React from "react";

export const SelectDate = (props) => {
  return (
    <div>
      <TimeSlotList />
    </div>
  );
};

export const TimeSlotList = () => {
  const startTime = new Date();
  startTime.setHours(0, 0, 0, 0); // Set start time to 12:00 AM

  const endTime = new Date();
  endTime.setHours(23, 45, 0, 0); // Set end time to 11:45 PM

  const timeSlots = [];

  while (startTime <= endTime) {
    timeSlots.push(new Date(startTime));
    startTime.setMinutes(startTime.getMinutes() + 15); // Increment by 15 minutes
  }

  return (
    <div>
      <h2>Time Slots for 24 Hours (15-minute increments)</h2>
      <ul>
        {timeSlots.map((timeSlot, index) => (
          <li key={index}>
            {timeSlot.toLocaleTimeString([], {
              hour: "2-digit",
              minute: "2-digit",
            })}
          </li>
        ))}
      </ul>
    </div>
  );
};
