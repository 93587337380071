import React, { useEffect, useState } from "react";
import { getHairGallery } from "../services/HairServices";

function SideImage(props) {
  const [allHair, setAllHair] = useState([]);
  useEffect(() => {
    const data = async () => {
      let data = await getHairGallery();
      setAllHair(data.splice(0, 20));
    };
    data();
  }, []);
  return (
    <div className="flex overflow-x-auto">
      {allHair.map((item) => (
        <React.Fragment key={item._id}>
          <img
            src={`${item.galleryImage}?w=248&fit=crop&auto=format`}
            srcSet={`${item.galleryImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
            alt={item.galleryName}
            loading="lazy"
            className="max-w-full max-h-32"
          />
        </React.Fragment>
      ))}
    </div>
  );
}

export default SideImage;
