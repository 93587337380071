import classNames from "classnames";
import React from "react";

function TailInput({
  labelName,
  htmlFor,
  type,
  name,
  id,
  placeholder,
  className,
  onChange,
  autoComplete,
  pattern,
  required,
  maxLength,
  disabled,
}) {
  return (
    <div className="items-baseline w-full">
      <label
        htmlFor={htmlFor}
        className=" flex text-sm font-medium  text-gray-900  items-start"
      >
        {labelName}
      </label>
      <div className="w-full ">
        <input
          required={required}
          pattern={pattern ?? null}
          autoComplete={autoComplete}
          type={type}
          name={name}
          disabled={disabled}
          id={id}
          maxLength={maxLength ?? null}
          onChange={onChange}
          className={classNames(
            "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 h-12"
          )}
          placeholder={placeholder}
        />
      </div>
    </div>
  );
}

export default TailInput;
/*
  user picks the day and time
  the time is in 30 min increments
  im having an issue with the date and time for this hair salon business
  the time needs to be in 30 minute increments 
  but lets say the it takes 4 hours to do the hair
  the next available time slot is 4 hours from the time the user picks
  so if the user picks 10:00 am the next available time slot is 2:00 pm
  if the user picks 10:30 am the next available time slot is 2:30 pm and so on
  now theres 4 hair stylist each hair stylist is available at different times
  lets say hair stylist 1 is available at 10:00 am and it takes them 3 hours to do the hair that means a book should be available at 1:00 pm
  but lets say hair stylist 2 is available at 10:00 am and it takes 4 hours 
  to do the hair that means a book should be available at 2:00 pm
  8:00 am
  6-8 2
  6-4 10
  6-2 8
  6-5 11
  
  9:00 pm

  im having an issue with the date and time for this hair salon business
  the business is open from 6:00 am to 9:00 pm
  i want the time to be in 15 minute increments

  I have 4 hairstylist each hairstylist is available at different times
  but they are all available at 6:00 am 
  [
    600: [{stylist1:available},
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    615: [{stylist1:available},
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    630: [{stylist1:available},
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    745: [{stylist1:available},
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    800: [{stylist1:available},
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
      ....
  ]
    but lets say hairstylist 1 is available at 6:00 am and it takes them 3 hours 
    to do the hair that means a book should be available at 9:00 am`
    and should be taken out of the array all the way will 900 
    example  [
    600: [

      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    615: [
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    630: [

      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    745: [

      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    800: [
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}
    ]
    815: [
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    830: [
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
    845: [
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
      
    900: [{stylist1:available},
      {stylist2:available},
      {stylist3:available},
      {stylist4:available}]
  ]


 */
