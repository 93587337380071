import { useEffect, useState } from "react";
import { Tab } from "@headlessui/react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

import { paymentIntentApi } from "../services/HairServices";
import Calendar from "../Calender/Calendar";
import TimeSlotGenerator from "./TimeSlotGenerator";
import { Link } from "react-router-dom";
import TimeSlotTabs from "./TimeSlotTabs";
import { Skeleton } from "@mui/material";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const CheckOutTabs = ({
  hairDataAPi,
  openSnackBar,
  setOpenSnackBar,
  selected,
  setSelected,
  successfulRedirect,
  setSuccessfulRedirect,
}) => {
  const stripePromise = loadStripe(
    "pk_live_51NqQPtD8C9a39WT3ZZzgvFhS04Nt0HRH9geIIsqxMfyl2uOy6ZCWQctqwWhKj1UtTnq8YYImOPF9EKCeZuEbQyUN00pONE3dR4"
  );
  const [clientSecret, setClientSecret] = useState("");
  const [clickedSelectedDate, setClickedSelectedDate] = useState(null);
  const [selectedDateInfo, setSelectedDateInfo] = useState(null);
  const [selectedTimeSlot, setSelectedTimeSlot] = useState(null);
  const [loadingTime, setLoadingTime] = useState(false);
  const [selectedTimeSlotId, setSelectedTimeSlotId] = useState(null);

  const appearance = {
    theme: "stripe",
  };
  const options = {
    clientSecret,
    appearance,
  };
  useEffect(() => {
    const intent = async () => {
      const intent = await paymentIntentApi();
      setClientSecret(intent.clientSecret);
    };
    intent();
  }, []);

  const handleButtonClick = () => {
    if (selectedTimeSlot === null) {
      return true;
    } else {
      return false;
    }
  };
  let fillArr = Array(25).fill(0);
  const LoadingSkeloton = () => {
    fillArr.map((item, index) => {
      return (
        <Skeleton
          sx={{ bgcolor: "grey.900" }}
          variant="rectangular"
          width={55}
          height={40}
        />
      );
    });
  };
  return (
    <div className="w-full  px-2 py-16 sm:px-0   ">
      <Link to="/book" className="p-2">
        <h1 className="text-gray-800 border w-32 rounded-full">Back</h1>
      </Link>
      <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1">
          <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            Calendar
          </Tab>
          <Tab
            disabled={handleButtonClick()}
            // onClick={handleButtonClick}
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            PAY
          </Tab>
        </Tab.List>
        <Tab.Panels className="mt-2">
          <Tab.Panel>
            <div className="flex items-center gap-2 flex-wrap justify-center ">
              <Calendar
                loadingTime={loadingTime}
                setLoadingTime={setLoadingTime}
                selected={selected}
                setSelected={setSelected}
                oneHair={hairDataAPi}
                clickedSelectedDate={clickedSelectedDate}
                setClickedSelectedDate={setClickedSelectedDate}
                selectedDateInfo={selectedDateInfo}
                setSelectedDateInfo={setSelectedDateInfo}
              />
              {loadingTime ? (
                <div className="text-center p-6">
                  <div className="grid grid-cols-4 gap-4 overflow-y-auto max-h-72 ">
                    {fillArr.map((item, index) => (
                      <Skeleton
                        sx={{ bgcolor: "grey.900" }}
                        variant="rectangular"
                        width={55}
                        height={40}
                      />
                    ))}
                  </div>
                </div>
              ) : (
                <TimeSlotTabs
                  selectedTimeSlotId={selectedTimeSlotId}
                  setSelectedTimeSlotId={setSelectedTimeSlotId}
                  setSelectedTimeSlot={setSelectedTimeSlot}
                  selectedTimeSlot={selectedTimeSlot}
                  selectedDateInfo={selectedDateInfo}
                  setSelectedDateInfo={setSelectedDateInfo}
                />
              )}
            </div>
          </Tab.Panel>
          <Tab.Panel>
            {clientSecret && (
              <Elements stripe={stripePromise} options={options}>
                <CheckoutForm
                  selected={selected}
                  selectedTimeSlot={selectedTimeSlot}
                  setSelected={setSelected}
                  oneHair={hairDataAPi}
                  setOpenSnackBar={setOpenSnackBar}
                  clickedSelectedDate={clickedSelectedDate}
                  setSuccessfulRedirect={setSuccessfulRedirect}
                  successfulRedirect={successfulRedirect}
                />
              </Elements>
            )}
            {/* <SelectComponent
              hairInfo={oneHair.length}
              selected={selected}
              setSelected={setSelected}
            /> */}
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>
    </div>
  );
};
// Here's a couple of websites. Which is close to something you would like?

// Do you have a hosting site/domain website that you use currently.

//  https://marshalabraids.com/

// https://www.swellabeauty.com/

// https://thebraidguru.com

// https://foreigncrown.com/appointments
