import React, { useEffect, useRef, useState } from "react";
import video1 from "./video1.mp4";
import video2 from "./video2.mp4";
import video3 from "./video3.mp4";
import video4 from "./video4.mp4";
import video5 from "./video5.mp4";
import video6 from "./video6.mp4";
import videojs from "video.js";
import "./BoxVideo.css";
// function BoxVideo(props) {
//   const videos = [video1, video2, video3, video4, video5, video6];
//   const videoRefs = useRef(videos.map(() => React.createRef()));
//   const totalVideos = videos.length;

//   useEffect(() => {
//     const playNextVideo = (index) => {
//       const currentVideo = videoRefs.current[index].current;
//       const nextVideoIndex = (index + 1) % totalVideos;
//       const nextVideo = videoRefs.current[nextVideoIndex].current;

//       currentVideo.pause();
//       currentVideo.currentTime = 0;
//       nextVideo.play();

//       setTimeout(
//         () => playNextVideo(nextVideoIndex),
//         nextVideo.duration * 1000
//       );
//     };

//     videos.forEach((video, index) => {
//       const videoElement = videoRefs.current[index].current;

//       videoElement.src = video;
//       videoElement.muted = true;

//       if (index === 0) {
//         videoElement.play();
//         setTimeout(() => playNextVideo(index), videoElement.duration * 1000);
//       }
//     });
//   }, [videos, totalVideos]);

//   return (
//     <div className="video-container">
//       {videos.map((_, index) => (
//         <video
//           key={index}
//           ref={videoRefs.current[index]}
//           controls
//           autoPlay
//           muted
//           className="video"
//         />
//       ))}
//     </div>
//   );
// }

// export default BoxVideo;

function BoxVideo(props) {
  const videos = [video1, video2, video3, video4, video5, video6];
  const videoRefs = useRef(videos.map(() => React.createRef()));
  const totalVideos = videos.length;
  let currentVideoIndex = 0;

  useEffect(() => {
    const playNextVideo = () => {
      const currentVideo = videoRefs.current[currentVideoIndex].current;
      const nextVideoIndex = (currentVideoIndex + 1) % totalVideos;
      const nextVideo = videoRefs.current[nextVideoIndex].current;

      const playPromise = nextVideo?.play();

      if (playPromise !== undefined) {
        playPromise
          .then(() => {
            currentVideo.pause();
            currentVideo.currentTime = 0;
          })
          .catch((error) => {
            // Auto-play was prevented, handle error
            console.error("Auto-play prevented:", error);
          });
      }

      currentVideoIndex = nextVideoIndex;
    };

    videos.forEach((video, index) => {
      const videoElement = videoRefs.current[index].current;

      videoElement.src = video;
      videoElement.muted = true;

      if (index === 0) {
        const player = videoElement;
        player.load(); // Load the first video
        const playPromise = player.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              setTimeout(playNextVideo, player.duration * 1000);
            })
            .catch((error) => {
              // Auto-play was prevented, handle error
              console.error("Auto-play prevented:", error);
            });
        }
      }
    });
  }, [videos, totalVideos]);

  return (
    <div className="video-container">
      {videos.map((_, index) => (
        <video
          key={index}
          ref={videoRefs.current[index]}
          controls
          autoPlay
          muted
          className="video"
        />
      ))}
    </div>
  );
}

export default BoxVideo;
