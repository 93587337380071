const TestingEmail = () => {
  return (
    <div style="font-family: 'Arial', sans-serif; background-color: #fff; color: #333; margin: 20px;">
      <h2 style="text-align: center; color: #ff66b2;">
        Hair Styling Order and Appointment Confirmation
      </h2>

      <p>
        Dear <span style="font-weight: bold; color: #ff66b2;">John Doe</span>,
      </p>

      <p>
        We are delighted to confirm your hair styling order and appointment
        details:
      </p>

      <ul style="list-style-type: none; padding: 0;">
        <li style="margin-bottom: 10px;">
          Email:{" "}
          <span style="font-weight: bold; color: #ff66b2;">
            john.doe@example.com
          </span>
        </li>
        <li style="margin-bottom: 10px;">
          Order ID:{" "}
          <span style="font-weight: bold; color: #ff66b2;">123456</span>
        </li>
        <li style="margin-bottom: 10px;">
          Appointment Time:{" "}
          <span style="font-weight: bold; color: #ff66b2;">
            January 15, 2024 at 2:00 PM
          </span>
        </li>
        <li style="margin-bottom: 10px;">
          Store Address:{" "}
          <span style="font-weight: bold; color: #ff66b2;">
            123 Main Street, Cityville
          </span>
        </li>
        <li style="margin-bottom: 10px;">
          Store Number:{" "}
          <span style="font-weight: bold; color: #ff66b2;">
            +1 123-456-7890
          </span>
        </li>
        <li style="margin-bottom: 10px;">
          Hairdress Name:{" "}
          <span style="font-weight: bold; color: #ff66b2;">Jane Stylist</span>
        </li>
        <li style="margin-bottom: 10px;">
          Hairdress Number:{" "}
          <span style="font-weight: bold; color: #ff66b2;">
            +1 987-654-3210
          </span>
        </li>
      </ul>

      <p>
        Thank you for choosing our hair styling services. If you have any
        questions or need further assistance, feel free to contact us.
      </p>

      <p style="text-align: center; color: #555;">
        Best regards,
        <br />
        Your Hair Styling Salon
      </p>
    </div>
  );
};
export default TestingEmail;
