import React from "react";

export const PAymentDescription = ({
  hairData,
  selectedPrice,
  selectedTimeSlot,
  clickedSelectedDate,
}) => {
  return (
    <dl className="mt-6 space-y-4">
      <div className="flex items-center justify-between">
        <dd className="text-lg font-black text-gray-900">{hairData.name}</dd>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="flex items-center text-lg font-black text-gray-600">
          <span>Price</span>
        </dt>
        <dd className="text-lg font-black text-gray-900">
          ${selectedPrice?.price}
        </dd>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="flex items-center text-lg font-black text-gray-600">
          <span>Deposit</span>
        </dt>
        <dd className="text-lg font-black text-gray-900">${50}</dd>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="flex text-lg font-black text-gray-600">
          <span>Remain Balance</span>
        </dt>
        <dd className="text-lg font-black text-gray-900">
          ${selectedPrice?.price - 50}
        </dd>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="flex text-lg font-black text-gray-600">
          <span> Date</span>
        </dt>
        <dd className="text-lg font-black text-gray-900">
          {clickedSelectedDate}
        </dd>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="flex text-lg font-black text-gray-600">
          <span>Time</span>
        </dt>
        <dd className="text-lg font-black  text-gray-900">
          {selectedTimeSlot?.time}
        </dd>
      </div>
      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="text-lg font-black text-gray-600">
          <span>Tax estimate</span>
        </dt>
        <dd className="text-lg font-black text-gray-900">$2.00</dd>
      </div>

      <div className="flex items-center justify-between border-t border-gray-200 pt-4">
        <dt className="text-lg font-black text-gray-900"> Deposit</dt>
        <dd className="text-lg font-black text-gray-900">$52.00</dd>
      </div>
    </dl>
  );
};
