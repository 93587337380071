import classNames from "classnames";
import React from "react";
import styles from "./PoliciesComp.module.css";
function PoliciesComp(props) {
  return (
    <div className={classNames(styles.PoliciesComp)}>
      <div className={classNames(styles.PoliciesCompContainer)}>
        <div className=" border  pl-6"></div>
        <div className=" p-8 rounded-lg shadow-md">
          <h2 className="text-2xl font-semibold mb-4">Hair Salon Policies</h2>
          <ul className="list-disc list-inside space-y-2">
            <li className="text-white">
              <strong className="font-medium text-white">Punctuality:</strong>{" "}
              We kindly request that you arrive on time for your scheduled
              appointment. Late arrivals may result in a shortened appointment
              time to accommodate subsequent clients. Your punctuality helps us
              maintain a smooth and efficient schedule for all our valued
              customers.
            </li>
            <li className="text-white">
              <strong className="font-medium">Take Down:</strong> If you require
              any hair take down services in addition to your scheduled
              treatment, please be aware that these services will incur an
              additional charge. Our team will be happy to discuss the details
              and pricing with you before proceeding.
            </li>
            <li className="text-white">
              <strong className="font-medium">Additional Services:</strong>{" "}
              Should you wish to add a hair washing service to your appointment,
              there will be an extra charge of $30. We believe that clean,
              well-prepared hair enhances the overall quality of your styling or
              treatment experience.
            </li>
            <li className="text-white">
              <strong className="font-medium">
                Appointment Changes and Cancellations:
              </strong>{" "}
              If you need to reschedule or cancel your appointment, we kindly
              ask for a minimum of 24 hours' notice. This allows us to
              accommodate other clients and efficiently manage our schedule.
              Late cancellations or no-shows may be subject to a cancellation
              fee.
            </li>
            <li className="text-white">
              <strong className="font-medium">Consultation:</strong> We
              encourage you to take advantage of our complimentary consultation
              services. Our expert stylists are here to listen to your
              preferences, offer recommendations, and ensure that you achieve
              the look you desire.
            </li>
            <li className="text-white">
              <strong className="font-medium">Children and Guests:</strong> For
              the comfort and enjoyment of all our clients, we kindly request
              that you do not bring children or additional guests unless they
              have appointments as well. Our salon environment is designed to
              provide a relaxing experience for all.
            </li>
            <li className="text-white">
              <strong className="font-medium">Respectful Environment:</strong>{" "}
              We are committed to fostering a respectful and positive atmosphere
              for all. We reserve the right to refuse service to anyone
              exhibiting disrespectful or inappropriate behavior towards our
              staff or fellow clients.
            </li>

            <li className="text-white">
              <strong className="font-medium">Payment:</strong> We accept
              various forms of payment, including cash, Zelle, CashApp, and
              mobile payment methods. Payment is due once you take a seat in the
              chair at the start of your appointment.
            </li>

            <li className="text-white">
              <strong className="font-medium">Feedback:</strong> Your
              satisfaction is of utmost importance to us. If you have any
              feedback or concerns about your experience, please don't hesitate
              to let us know. We value your input as it helps us continuously
              improve our services.
            </li>
            <li className="text-white">
              <strong className="font-medium">Allergies:</strong> It is
              important to inform us of any known allergies to braiding hair and
              / or hair products. By booking with Destiny Hair braiding, you are
              agreeing to all terms.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PoliciesComp;
