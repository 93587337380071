import Sénégalese from "./img/Sénégalesetwist.jpg";
import largenotless from "./img/largenotless.jpg";
import mediumsquareboxbraids from "./img/mediumsquareboxbraids.jpg";
import smallnotlessbraids from "./img/smallnotlessbraid.jpg";
import smallsenegalesetwist from "./img/smallsenegalesetwist.jpg";
import extrasmallnotlessbraids from "./img/extrasmallnotlessbraids.jpg";
import meduimnotlessbraid from "./img/meduimnotlessbraid.jpg";
import extrasmallsenegalesetwist from "./img/extrasmallsenegalesetwist.jpg";
export const AllProducts = [
  {
    name: "Small Sénégalese twist ",
    img: smallsenegalesetwist,
    id: 123,
    price: "320",
    time: "6-14 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      {
        waist: "60",
        time: "8-12 hours",
        size: "Waist Length",
        price: "60",
      },
      {
        buttLength: "120",
        time: "10-15 hours",
        size: "Butt Length",
        price: "120",
      },
      {
        kneeLength: "280",
        time: "8-10 hours",
        size: "Knee Length",
        price: "280",
      },
      { midBack: "0", time: "5-8 hours", size: "Mid Back", price: "0" },
    ],
  },
  {
    name: "extra extra small Sénégalese twist",
    img: extrasmallsenegalesetwist,
    id: 123,
    price: "440",
    time: "8 - 14 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "5-8 hours", size: "Mid Back", price: "20", total: "460" },
      {
        time: "10-14 hours",
        size: "Waist Length",
        price: "240",
        total: "680",
      },
      {
        time: "10-16 hours",
        size: "Butt Length",
        price: "440",
        total: "880",
      },
      {
        time: "8-10 hours",
        size: "Knee Length",
        price: "760",
        total: "1200",
      },
    ],
  },
  {
    name: "Small Square Box braids",
    img: Sénégalese,
    id: 1243,
    price: "280",
    time: "5-6 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "5-8 hours", size: "Mid Back", price: "0", total: "280" },
      {
        time: "6-8 hours",
        size: "Waist Length",
        price: "60",
        total: "340",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "400",
      },
      {
        time: "10-12 hours",
        size: "Knee Length",
        price: "220",
        total: "500",
      },
    ],
  },
  {
    name: "medium square Box braids",
    img: mediumsquareboxbraids,
    id: 1243,
    price: "260",
    time: "3-4 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "3-4 hours", size: "Mid Back", price: "0", total: "260" },
      {
        time: "5-6 hours",
        size: "Waist Length",
        price: "40",
        total: "300",
      },
      {
        time: "6-8 hours",
        size: "Butt Length",
        price: "80",
        total: "340",
      },
      {
        time: "6-8 hours",
        size: "Knee Length",
        price: "120",
        total: "380",
      },
    ],
  },
  {
    name: "Large square Box braids",
    img: Sénégalese,
    id: 1243,
    price: "220",
    time: "3-4 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "3-4 hours", size: "Mid Back", price: "0", total: "220" },
      {
        time: "5-6 hours",
        size: "Waist Length",
        price: "60",
        total: "280",
      },
      {
        time: "4-6 hours",
        size: "Butt Length",
        price: "100",
        total: "320",
      },
      {
        time: "6-8 hours",
        size: "Knee Length",
        price: "140",
        total: "360",
      },
    ],
  },
  {
    name: "small box braids",
    img: Sénégalese,
    id: 12434,
    price: "300",
    time: "5-6 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "5-6 hours", size: "Mid Back", price: "0", total: "300" },
      {
        time: "6-8 hours",
        size: "Waist Length",
        price: "60",
        total: "360",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "420",
      },
      {
        time: "10-14 hours",
        size: "Knee Length",
        price: "220",
        total: "520",
      },
    ],
  },
  {
    name: "extra small notless braids",
    img: extrasmallnotlessbraids,
    id: 12434,
    price: "400",
    time: "6-8 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "400" },
      {
        time: "8-10 hours",
        size: "Waist Length",
        price: "60",
        total: "460",
      },
      {
        time: "10-12 hours",
        size: "Butt Length",
        price: "120",
        total: "520",
      },
      {
        time: "12-16 hours",
        size: "Knee Length",
        price: "220",
        total: "620",
      },
    ],
  },
  {
    name: "Small notless braids",
    img: smallnotlessbraids,

    id: 12434,
    price: "320",
    time: "6-8 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "320" },
      {
        time: "8-10 hours",
        size: "Waist Length",
        price: "60",
        total: "380",
      },
      {
        time: "10-12 hours",
        size: "Butt Length",
        price: "100",
        total: "420",
      },
      {
        time: "10-14 hours",
        size: "Knee Length",
        price: "200",
        total: "520",
      },
    ],
  },
  {
    name: "Medium notless braids",
    img: meduimnotlessbraid,
    id: 12434,
    price: "280",
    time: "4-5 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "4-5 hours", size: "Mid Back", price: "0", total: "280" },
      {
        time: "6-8 hours",
        size: "Waist Length",
        price: "60",
        total: "340",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "400",
      },
      {
        time: "10-12 hours",
        size: "Knee Length",
        price: "200",
        total: "480",
      },
    ],
  },
  {
    name: "Large notless braids",
    img: Sénégalese,
    id: 12434,
    price: "220",
    time: "4-5 hours",
    bringHair: false,
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "4-5 hours", size: "Mid Back", price: "0", total: "280" },
      {
        time: "6-8 hours",
        size: "Waist Length",
        price: "60",
        total: "280",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "330",
      },
      {
        time: "10-12 hours",
        size: "Knee Length",
        price: "200",
        total: "400",
      },
    ],
  },
  {
    name: "small bohemian notless braids ",
    img: Sénégalese,
    id: 12434,
    price: "480",
    time: "6-8 hours",
    bringHair: false,
    downPayment: "150",
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "480" },
      {
        time: "8-10 hours",
        size: "Waist Length",
        price: "60",
        total: "540",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "640",
      },
      {
        time: "10-12 hours",
        size: "Knee Length",
        price: "200",
        total: "740",
      },
    ],
  },
  {
    name: "medium bohemian notless braids ",
    img: Sénégalese,
    id: 12434,
    price: "440",
    time: "6-8 hours",
    bringHair: false,
    downPayment: "150",
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "440" },
      {
        time: "8-10 hours",
        size: "Waist Length",
        price: "60",
        total: "500",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "620",
      },
      {
        time: "10-12 hours",
        size: "Knee Length",
        price: "200",
        total: "720",
      },
    ],
  },
  {
    name: "large bohemian notless braids ",
    img: largenotless,
    id: 12434,
    price: "380",
    time: "6-8 hours",
    bringHair: false,
    downPayment: "150",
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "380" },
      {
        time: "8-10 hours",
        size: "Waist Length",
        price: "60",
        total: "440",
      },
      {
        time: "8-10 hours",
        size: "Butt Length",
        price: "120",
        total: "560",
      },
      {
        time: "10-12 hours",
        size: "Knee Length",
        price: "200",
        total: "620",
      },
    ],
  },
  {
    name: "tribal braid ",
    img: largenotless,
    id: 12434,
    price: "320",
    time: "5-6 hours",
    bringHair: false,
    downPayment: "150",
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "320" },
      {
        time: "6-7 hours",
        size: "Waist Length",
        price: "60",
        total: "360",
      },
      {
        time: "6-8 hours",
        size: "Butt Length",
        price: "120",
        total: "380",
      },
      {
        time: "8-10 hours",
        size: "Knee Length",
        price: "200",
        total: "440",
      },
    ],
  },
  {
    name: "small ponytail ",
    img: largenotless,
    id: 12434,
    price: "250",
    time: "5-6 hours",
    bringHair: false,
    downPayment: "150",
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "250" },
      {
        time: "6-7 hours",
        size: "Waist Length",
        price: "60",
        total: "280",
      },
      {
        time: "6-8 hours",
        size: "Butt Length",
        price: "120",
        total: "320",
      },
      {
        time: "8-10 hours",
        size: "Knee Length",
        price: "200",
        total: "360",
      },
    ],
  },
  {
    name: "small lemonde brais ",
    img: largenotless,
    id: 12434,
    price: "250",
    time: "5-6 hours",
    bringHair: false,
    downPayment: "150",
    description:
      "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
    length: [
      { time: "6-8 hours", size: "Mid Back", price: "0", total: "250" },
      {
        time: "6-7 hours",
        size: "Waist Length",
        price: "60",
        total: "280",
      },
      {
        time: "6-8 hours",
        size: "Butt Length",
        price: "120",
        total: "320",
      },
      {
        time: "8-10 hours",
        size: "Knee Length",
        price: "200",
        total: "360",
      },
    ],
  },
];
let section = {
  cornrow: [
    {
      name: "small lemonade  ",
      img: largenotless,
      id: 12434,
      price: "250",
      time: "5-6 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "250" },
        {
          time: "6-7 hours",
          size: "Waist Length",
          price: "60",
          total: "280",
        },
        {
          time: "6-8 hours",
          size: "Butt Length",
          price: "120",
          total: "320",
        },
        {
          time: "8-10 hours",
          size: "Knee Length",
          price: "200",
          total: "360",
        },
      ],
    },
    {
      name: "small ponytail ",
      img: largenotless,
      id: 12434,
      price: "250",
      time: "5-6 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "250" },
        {
          time: "6-7 hours",
          size: "Waist Length",
          price: "60",
          total: "280",
        },
        {
          time: "6-8 hours",
          size: "Butt Length",
          price: "120",
          total: "320",
        },
        {
          time: "8-10 hours",
          size: "Knee Length",
          price: "200",
          total: "360",
        },
      ],
    },
    {
      name: "tribal braid ",
      img: largenotless,
      id: 12434,
      price: "320",
      time: "5-6 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "320" },
        {
          time: "6-7 hours",
          size: "Waist Length",
          price: "60",
          total: "360",
        },
        {
          time: "6-8 hours",
          size: "Butt Length",
          price: "120",
          total: "380",
        },
        {
          time: "8-10 hours",
          size: "Knee Length",
          price: "200",
          total: "440",
        },
      ],
    },
  ],

  braids: [
    {
      name: "small box braids",
      img: Sénégalese,
      id: 12434,
      price: "300",
      time: "5-6 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "5-6 hours", size: "Mid Back", price: "0", total: "300" },
        {
          time: "6-8 hours",
          size: "Waist Length",
          price: "60",
          total: "360",
        },
        {
          time: "8-10 hours",
          size: "Butt Length",
          price: "120",
          total: "420",
        },
        {
          time: "10-14 hours",
          size: "Knee Length",
          price: "220",
          total: "520",
        },
      ],
    },
    {
      name: "extra small notless braids",
      img: extrasmallnotlessbraids,
      id: 12434,
      price: "400",
      time: "6-8 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "400" },
        {
          time: "8-10 hours",
          size: "Waist Length",
          price: "60",
          total: "460",
        },
        {
          time: "10-12 hours",
          size: "Butt Length",
          price: "120",
          total: "520",
        },
        {
          time: "12-16 hours",
          size: "Knee Length",
          price: "220",
          total: "620",
        },
      ],
    },
    {
      name: "Small notless braids",
      img: smallnotlessbraids,

      id: 12434,
      price: "320",
      time: "6-8 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "320" },
        {
          time: "8-10 hours",
          size: "Waist Length",
          price: "60",
          total: "380",
        },
        {
          time: "10-12 hours",
          size: "Butt Length",
          price: "100",
          total: "420",
        },
        {
          time: "10-14 hours",
          size: "Knee Length",
          price: "200",
          total: "520",
        },
      ],
    },
    {
      name: "Medium notless braids",
      img: meduimnotlessbraid,
      id: 12434,
      price: "280",
      time: "4-5 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "4-5 hours", size: "Mid Back", price: "0", total: "280" },
        {
          time: "6-8 hours",
          size: "Waist Length",
          price: "60",
          total: "340",
        },
        {
          time: "8-10 hours",
          size: "Butt Length",
          price: "120",
          total: "400",
        },
        {
          time: "10-12 hours",
          size: "Knee Length",
          price: "200",
          total: "480",
        },
      ],
    },
    {
      name: "Large notless braids",
      img: Sénégalese,
      id: 12434,
      price: "220",
      time: "4-5 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "4-5 hours", size: "Mid Back", price: "0", total: "280" },
        {
          time: "6-8 hours",
          size: "Waist Length",
          price: "60",
          total: "280",
        },
        {
          time: "8-10 hours",
          size: "Butt Length",
          price: "120",
          total: "330",
        },
        {
          time: "10-12 hours",
          size: "Knee Length",
          price: "200",
          total: "400",
        },
      ],
    },
    {
      name: "small bohemian notless braids ",
      img: Sénégalese,
      id: 12434,
      price: "480",
      time: "6-8 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "480" },
        {
          time: "8-10 hours",
          size: "Waist Length",
          price: "60",
          total: "540",
        },
        {
          time: "8-10 hours",
          size: "Butt Length",
          price: "120",
          total: "640",
        },
        {
          time: "10-12 hours",
          size: "Knee Length",
          price: "200",
          total: "740",
        },
      ],
    },
    {
      name: "medium bohemian notless braids ",
      img: Sénégalese,
      id: 12434,
      price: "440",
      time: "6-8 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "440" },
        {
          time: "8-10 hours",
          size: "Waist Length",
          price: "60",
          total: "500",
        },
        {
          time: "8-10 hours",
          size: "Butt Length",
          price: "120",
          total: "620",
        },
        {
          time: "10-12 hours",
          size: "Knee Length",
          price: "200",
          total: "720",
        },
      ],
    },
    {
      name: "large bohemian notless braids ",
      img: largenotless,
      id: 12434,
      price: "380",
      time: "6-8 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "380" },
        {
          time: "8-10 hours",
          size: "Waist Length",
          price: "60",
          total: "440",
        },
        {
          time: "8-10 hours",
          size: "Butt Length",
          price: "120",
          total: "560",
        },
        {
          time: "10-12 hours",
          size: "Knee Length",
          price: "200",
          total: "620",
        },
      ],
    },
    {
      name: "tribal braid ",
      img: largenotless,
      id: 12434,
      price: "320",
      time: "5-6 hours",
      bringHair: false,
      downPayment: "150",
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "6-8 hours", size: "Mid Back", price: "0", total: "320" },
        {
          time: "6-7 hours",
          size: "Waist Length",
          price: "60",
          total: "360",
        },
        {
          time: "6-8 hours",
          size: "Butt Length",
          price: "120",
          total: "380",
        },
        {
          time: "8-10 hours",
          size: "Knee Length",
          price: "200",
          total: "440",
        },
      ],
    },
  ],

  twist: [
    {
      name: "Small Sénégalese twist ",
      img: smallsenegalesetwist,
      id: 123,
      price: "320",
      time: "6-14 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        {
          time: "8-12 hours",
          size: "Waist Length",
          price: "60",
        },
        {
          time: "10-15 hours",
          size: "Butt Length",
          price: "120",
        },
        {
          time: "8-10 hours",
          size: "Knee Length",
          price: "280",
        },
        { time: "5-8 hours", size: "Mid Back", price: "0" },
      ],
    },
    {
      name: "extra extra small Sénégalese twist",
      img: extrasmallsenegalesetwist,
      id: 123,
      price: "440",
      time: "8 - 14 hours",
      bringHair: false,
      description:
        "Sénégalese twists are a beautiful and long-lasting protective style that protects your natural hair from the elements and over manipulation. They promote hair growth and provide length, volume, and color without the need for daily styling or hair dye.",
      length: [
        { time: "5-8 hours", size: "Mid Back", price: "20", total: "460" },
        {
          time: "10-14 hours",
          size: "Waist Length",
          price: "240",
          total: "680",
        },
        {
          time: "10-16 hours",
          size: "Butt Length",
          price: "440",
          total: "880",
        },
        {
          time: "8-10 hours",
          size: "Knee Length",
          price: "760",
          total: "1200",
        },
      ],
    },
  ],
  locks: [],
  kids: [],
};
