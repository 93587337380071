import React, { useState, Children } from "react";
import Products from "../Product/Products";
import classNames from "classnames";
import styles from "./TabsSection.module.css";

const Tabs = ({ children, initialActiveTab }) => {
  const defaultLabel = "All";
  const tabElements = Children.toArray(children);

  const initialTab = tabElements.find(
    (child) => child.props.label === initialActiveTab
  );
  const [activeTab, setActiveTab] = useState(
    initialTab ? initialTab.props.label : defaultLabel
  );

  function handleClickTabItem(tabLabel) {
    setActiveTab(tabLabel);
  }

  function capitalizeFirstLetter(inputString) {
    if (inputString.length > 0) {
      return inputString[0].toUpperCase() + inputString.slice(1);
    } else {
      return inputString;
    }
  }

  return (
    <div className={classNames(styles.TabsContainer, "tabs flex gap-2")}>
      <div className={classNames(styles.TopTabsContainer, "tabs flex gap-2")}>
        <div className={classNames(styles.TopTabs, "tabs flex gap-2")}>
          {tabElements.map((child) => {
            const { label } = child.props;
            return (
              <button
                className={classNames(styles.ButtonTabs, `flex `, {
                  [styles.activeTab]: label === activeTab,
                })}
                key={label || defaultLabel}
                onClick={() => handleClickTabItem(label || defaultLabel)}
              >
                {capitalizeFirstLetter(label) || defaultLabel}
              </button>
            );
          })}
        </div>
      </div>

      <div className={classNames(styles.tabsSection, "")}>
        {tabElements.map((child) => {
          if (!child.props.label || child.props.label !== activeTab)
            return null;
          return child.props.children;
        })}
      </div>
    </div>
  );
};

const Tab = ({ label, children }) => {
  return children; // Return the children directly
};

function TabsSection({ getAllHair }) {
  const hairCategories = [
    { hairCategory: "cornrow", active: false, id: 1 },
    { hairCategory: "braids", active: false, id: 2 },
    { hairCategory: "locks", active: false, id: 3 },
    { hairCategory: "twist", active: false, id: 4 },
    { hairCategory: "kids", active: false, id: 5 },
  ];

  return (
    <Tabs>
      <Tab label="All">
        <div
          className={classNames(styles.Cards, "relative isolate pt-13 home")}
        >
          {getAllHair.map((item) => (
            <React.Fragment key={item._id}>
              <Products
                name={item.name}
                image={item.img}
                hairType={item}
                id={item._id}
                price={item.price}
                hairLength={item.hairLength}
              />
            </React.Fragment>
          ))}
        </div>
      </Tab>
      {hairCategories.map((category) => (
        <Tab key={category.id} label={category.hairCategory}>
          <div
            className={classNames("bg-custom-50 relative isolate pt-14 home")}
          >
            {getAllHair
              .filter((item) => item.tags.includes(category.hairCategory))
              .map((item) => (
                <React.Fragment key={item._id}>
                  <Products
                    name={item.name}
                    image={item.img}
                    hairType={item}
                    id={item._id}
                    price={item.price}
                    hairLength={item.hairLength}
                  />
                </React.Fragment>
              ))}
          </div>
        </Tab>
      ))}
    </Tabs>
  );
}

export default TabsSection;
