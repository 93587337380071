import React, { useEffect, useState } from "react";
import Footers from "./Footer/Footers";
import Products from "./Product/Products";
import "./HomePage.css";
import VideoPlayer from "./Videoplayer/Video";
import { AllProducts } from "./AllPRoducts/AllProduct";
import { getAllHairApi } from "./services/HairServices";
import TabsSection from "./TabsSection/TabsSection";
import classNames from "classnames";
import SideImage from "./SideImage.jsx/SideImage";
import PoliciesComp from "./policiesComp/PoliciesComp";
import BoxVideo from "./BoxVideo/BoxVideo";
import NavBar from "./NavBar/NavBar";

export default function HomePage() {
  const [hair] = useState(AllProducts);

  const [getAllHair, setGetAllHair] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      let data = await getAllHairApi();
      setGetAllHair(data);
    };
    fetchData();
  }, []);
  return (
    <div className={classNames("bg-custom-50 w-full")}>
      {/* <div>
        <NavBar />
      </div> */}
      <div>
        <VideoPlayer />
      </div>
      <div>
        <SideImage />
      </div>
      <div className={classNames("HomePageTabsContainer", "bg-white ")}>
        <TabsSection getAllHair={getAllHair} />
      </div>
      <BoxVideo />
      <PoliciesComp />
    </div>
  );
}
