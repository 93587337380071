import React, { useEffect, useState } from "react";
import {
  PaymentElement,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";

import TailInput from "../uiComponent/TailInput";
import classNames from "classnames";
import "./CheckoutPage.css";
import { PAymentDescription } from "./PAymentDescription";

import { useNavigate, useParams } from "react-router-dom";
import Policies from "./Policies";
import { useCreateSuccessfulMessageContext } from "../context/SuccessfulMessageContext";
import { confirmedPaymentApi } from "../services/HairServices";

export default function CheckoutForm({
  oneHair,
  setOpenSnackBar,
  openSnackBar,
  selected,
  setSelected,
  selectedTimeSlot,
  clickedSelectedDate,
}) {
  const { successfulRedirect, setSuccessfulRedirect } =
    useCreateSuccessfulMessageContext();
  const stripe = useStripe();
  const elements = useElements();
  const [formData, setFormData] = useState({});
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [policies, setPolicies] = useState(false);
  const { id } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "payment_intent_client_secret"
    );
    if (!clientSecret) {
      return;
    }
    stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      switch (paymentIntent.status) {
        case "succeeded":
          setOpenSnackBar({
            open: true,
            text: "Payment succeeded!",
          });
          break;
        case "processing":
          setMessage("Your payment is processing.");
          setOpenSnackBar({
            open: true,
            text: "Your payment is processing.",
          });
          break;
        case "requires_payment_method":
          setMessage("Your payment was not successful, please try again.");
          setOpenSnackBar({
            open: true,
            text: "Your payment was not successful, please try again.",
          });
          break;
        default:
          setMessage("Something went wrong.");
          setOpenSnackBar({
            open: true,
            text: "Something went wrong.",
          });
          break;
      }
    });
  }, [stripe]);

  const handleFormDataChange = (e) => {
    setFormData({
      ...formData,
      hairId: id,
      date: clickedSelectedDate,
      time: selectedTimeSlot,
      [e.target.name]: e.target.value,
      sizeSelected: selected,
      selectedHair: oneHair.name,
    });
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      console.error("Client Secret Error!");
      setIsLoading(false);
      return;
    }
    setIsLoading(true);
    elements.submit();
    setSuccessfulRedirect(true);
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: {
        return_url: `https://destinyafricanhairbraiding.org/success/?paymentSuccess=true&firstName=${formData.firstName}&email=${formData.email}&phoneNumber=${formData.phoneNumber}&hair=${formData.selectedHair}&date=${formData.date}&time=${formData.time.time}&size=${formData.sizeSelected}&finishedHairTime=${oneHair.time}&lastName=${formData.lastName}&hairId=${formData.hairId}&selected=${selected.size}`,
      },
      redirect: "if_required",
      billing_details: {
        name: formData.firstName + " " + formData.lastName,
      },
    });
    console.log(error, "=== erorr herer");
    if (error?.type === "card_error" || error?.type === "validation_error") {
      setSuccessfulRedirect(false);
      setMessage(error.message);
      setOpenSnackBar({
        open: true,
        text: error.message,
      });
    } else {
      await confirmedPaymentApi(formData);
      setTimeout(() => {
        navigate("/success");
      }, 2000);
    }
    setIsLoading(false);
  };

  const paymentElementOptions = {
    layout: "tabs",
  };
  return (
    <div
      className={classNames(
        "flex gap-6 flex-wrap-reverse justify-center w-full"
      )}
    >
      <form
        id="payment-form"
        onSubmit={handleSubmit}
        className="flex  flex-col gap-6"
      >
        <div>
          <TailInput
            labelName={"Hair Length"}
            htmlFor={"firstName"}
            onChange={handleFormDataChange}
            placeholder={selected.size}
            type={"text"}
            disabled={true}
            autoComplete="name"
            name={"firstName"}
            id={"text"}
            required={true}
          />
        </div>
        <div className="flex gap-4">
          <TailInput
            labelName={"Name"}
            htmlFor={"firstName"}
            onChange={handleFormDataChange}
            placeholder={"First Name"}
            type={"text"}
            autoComplete="name"
            name={"firstName"}
            id={"text"}
            required={true}
          />
          <TailInput
            labelName={"Last Name"}
            htmlFor={"name"}
            autoComplete="last name"
            placeholder={"Last Name"}
            type={"text"}
            onChange={handleFormDataChange}
            name={"lastName"}
            id={"text"}
            required={true}
          />
        </div>
        <div className="flex gap-3">
          <TailInput
            labelName={"Number"}
            onChange={handleFormDataChange}
            htmlFor={"number"}
            placeholder={"111-111-1111"}
            maxLength="10"
            type={"tel"}
            name={"phoneNumber"}
            id={"number"}
            required={true}
          />
          <TailInput
            labelName={"Email"}
            onChange={handleFormDataChange}
            htmlFor={"email"}
            placeholder={"example@gmail.com"}
            type={"email"}
            name={"email"}
            id={"email"}
            required={true}
          />
        </div>
        <PaymentElement id="payment-element" options={paymentElementOptions} />
        <Policies policies={policies} setPolicies={setPolicies} />
        <button
          className="rounded-md  bg-custom-200 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-custom-200 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 w-full "
          disable={!stripe || !elements}
          id="submit"
          type="submit"
        >
          Pay Now
        </button>
      </form>
      <div>
        <div>
          <img
            src={oneHair?.img}
            alt=""
            className={classNames("checkoutImage ")}
          />
        </div>
        <div>
          <PAymentDescription
            selectedTimeSlot={selectedTimeSlot}
            clickedSelectedDate={clickedSelectedDate}
            hairData={oneHair}
            selectedPrice={selected}
          />
        </div>
      </div>
    </div>
  );
}
