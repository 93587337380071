import React from "react";
import { Tab } from "@headlessui/react";
import TimeSlotGenerator from "./TimeSlotGenerator";
import NightTimeSlot from "./NightTimeSlot";
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}
function TimeSlotTabs({
  selectedDateInfo,
  setSelectedDateInfo,
  selectedTimeSlot,
  setSelectedTimeSlot,
  selectedTimeSlotId,
  setSelectedTimeSlotId,
}) {
  return (
    <div>
      {/* <Tab.Group>
        <Tab.List className="flex space-x-1 rounded-xl bg-blue-900/20 p-1"> */}
      {/* <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            Day Time
          </Tab> */}
      {/* <Tab
            className={({ selected }) =>
              classNames(
                "w-full rounded-lg py-2.5 text-sm font-medium leading-5 text-blue-700",
                "ring-white ring-opacity-60 ring-offset-2 ring-offset-blue-400 focus:outline-none focus:ring-2",
                selected
                  ? "bg-white shadow"
                  : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            Night Time
          </Tab> */}
      {/* </Tab.List>
        <Tab.Panels> */}
      {/* <Tab.Panel> */}
      <TimeSlotGenerator
        selectedTimeSlotId={selectedTimeSlotId}
        setSelectedTimeSlotId={setSelectedTimeSlotId}
        selectedTimeSlot={selectedTimeSlot}
        setSelectedTimeSlot={setSelectedTimeSlot}
        selectedDateInfo={selectedDateInfo}
        setSelectedDateInfo={setSelectedDateInfo}
      />
      {/* </Tab.Panel>
          <Tab.Panel> */}
      {/* <NightTimeSlot
              selectedDateInfo={selectedDateInfo}
              setSelectedDateInfo={setSelectedDateInfo}
            /> */}
      {/* </Tab.Panel>
        </Tab.Panels> */}
      {/* </Tab.Group> */}
    </div>
  );
}

export default TimeSlotTabs;
