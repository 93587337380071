import "./App.css";
import { Route, Routes } from "react-router";
import HomePage from "./HomePage";
import NavBar from "./NavBar/NavBar";
import CheckoutPage from "./CheckoutPage/CheckoutPage";
import EntryPage from "./EntryPage/EntryPage";
import CreateHair from "./CreateHair/CreateHair";
import { Elements } from "@stripe/react-stripe-js";
import { SelectDate } from "./SelectDate/SelectDate";
import AllGallery from "./AllGallery/AllGallery";
import AboutMe from "./AboutMe/AboutMe";
import TestingEmail from "./TestingEmail/TestingEmail";
import Success from "./Success/Success";
import { useState } from "react";
function App() {
  const [successfulRedirect, setSuccessfulRedirect] = useState(false)
  return (
    <div className="App">
      <NavBar />
      <Routes>
        <Route path="/book" element={<HomePage />} />
        <Route path="/about" element={<AboutMe />} />
        <Route path="/" element={<EntryPage />} />
        <Route path="/checkout/:id" element={<CheckoutPage
          successfulRedirect={successfulRedirect}
          setSuccessfulRedirect={setSuccessfulRedirect}
        />} />
        <Route path="/selectTime" element={<SelectDate />} />
        <Route path="/gallery" element={<AllGallery />} />
        <Route path="/testing" element={<TestingEmail />} />
        <Route path="/success" element={
          <Success />} />
      </Routes>
    </div>
  );
}

export default App;
