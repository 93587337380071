import React, { useEffect, useState } from "react";
import { getHairGallery } from "../services/HairServices";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { Link } from "react-router-dom";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ImageModal from "./ImageModal";
import PoliciesComp from "../policiesComp/PoliciesComp";
function AllGallery(props) {
  const [allHair, setAllHair] = useState([]);
  const [open, setOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  useEffect(() => {
    const data = async () => {
      let data = await getHairGallery();
      setAllHair(data);
    };
    data();
  }, []);
  const handleOpen = (img) => {
    setOpen(true);
    setImageUrl(img);
  };
  return (
    <div className="bg-white">
      <div className="mx-auto max-w-screen-2xl px-4 md:px-8">
        <div className=" w-full">
          <ImageList variant="masonry" cols={3} gap={8}>
            {allHair.map((item) => (
              <React.Fragment key={item._id}>
                <ImageListItem onClick={() => handleOpen(item.galleryImage)}>
                  <img
                    src={`${item.galleryImage}?w=248&fit=crop&auto=format`}
                    srcSet={`${item.galleryImage}?w=248&fit=crop&auto=format&dpr=2 2x`}
                    alt={item.galleryName}
                    loading="lazy"
                  />
                </ImageListItem>
              </React.Fragment>
            ))}
            <ImageModal open={open} setOpen={setOpen} imageItem={imageUrl} />
          </ImageList>
        </div>
      </div>
      <PoliciesComp />
    </div>
  );
}

export default AllGallery;
