import classNames from "classnames";
import destinyLogo from "./destinyLogo.JPG";
import { useState } from "react";

function TimeSlotGenerator({
  selectedDateInfo,
  setSelectedDateInfo,
  selectedTimeSlot,
  setSelectedTimeSlot,
  selectedTimeSlotId,
  setSelectedTimeSlotId,
}) {
  const handleTimeSlotClick = (timeSlotId) => {
    setSelectedTimeSlot(timeSlotId);
    setSelectedTimeSlotId(timeSlotId.id);
  };

  return (
    // <div className="text-center p-6">
    //   <div className="grid grid-cols-4 gap-4 overflow-y-auto max-h-72 border ">
    //     {selectedDateInfo ? (
    //       selectedDateInfo?.daytime?.map((timeSlot) => (
    //         <div
    //           key={timeSlot.id}
    //           className={classNames(
    //             `border p-2 text-center cursor-pointer  ${
    //               selectedTimeSlotId === timeSlot.id ? "bg-custom-200" : ""
    //             }`
    //           )}
    //           onClick={() => handleTimeSlotClick(timeSlot)}
    //         >
    //           {timeSlot.time}
    //         </div>
    //       ))
    //     ) : (
    //       <div className={classNames("border")}>
    //         <img src={destinyLogo} alt="destiny" className="border" />
    //         <h1>Please select a Da</h1>
    //       </div>
    //     )}
    //   </div>
    // </div>
    <div className="text-center p-6">
      <div className="grid grid-cols-4 gap-4 overflow-y-auto max-h-72 border ">
        {selectedDateInfo?.daytime?.map((timeSlot) => (
          <div
            key={timeSlot.id}
            className={classNames(
              `border p-2 text-center cursor-pointer  ${
                selectedTimeSlotId === timeSlot.id ? "bg-custom-200" : ""
              }`
            )}
            onClick={() => handleTimeSlotClick(timeSlot)}
          >
            {timeSlot.time}
          </div>
        ))}
      </div>
      {!selectedDateInfo && (
        <div className={classNames("border")}>
          <h1 className={classNames("")}>Please select a Date</h1>
          <img
            src={destinyLogo}
            alt="destiny"
            className="border w-[25rem] h-[9rem]"
          />
        </div>
      )}
    </div>
  );
}

export default TimeSlotGenerator;
