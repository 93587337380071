import dayjs from "dayjs";
import { v4 as uuidV4 } from "uuid";
import { checkDayAvailability } from "../services/HairServices";

export const months = {
  0: "January",
  1: "February",
  2: "March",
  3: "April",
  4: "May",
  5: "June",
  6: "July",
  7: "August",
  8: "September",
  9: "October",
  10: "November",
  11: "December",
};

export const daysOfTheWeek = {
  0: "Sunday",
  1: "Monday",
  2: "Tuesday",
  3: "Wednesday",
  4: "Thursday",
  5: "Friday",
  6: "Saturday",
};

export const calender = (
  year = dayjs().year(),
  monthNumber = dayjs().month()
) => {
  const lastDayOfTheMonth = dayjs()
    .year(year)
    .month(monthNumber)
    .endOf("month");

  const calender = [];
  const month = months[monthNumber];

  for (let day = 1; day <= lastDayOfTheMonth.date(); day += 1) {
    const dayOfTheWeek =
      daysOfTheWeek[dayjs().year(year).month(monthNumber).date(day).day()];
    const date = new Date(`${month} ${day}, ${year}`);
    const currentDate = new Date();
    const dateData = {
      day,
      month,
      year,
      availableHours: [],
      dayOfTheWeek,
      available: currentDate <= date,
    };
    calender.push(dateData);
  }

  return { month, year, calender, monthNumber };
};
export const generateTimeRanges = async (selectedDay) => {
  const checkAvailability = await checkDayAvailability(selectedDay);
  const daytime = checkAvailability;
  const nighttime = generateTimeSlots(new Date(selectedDay));
  return { daytime, nighttime };
};

const generateTimeSlots = (targetDate) => {
  const currentTime = new Date(); // Get the current time
  const startTime = new Date(targetDate);
  startTime.setHours(6, 0, 0); // Set start time to 6:00 AM

  const endTime = new Date(targetDate);
  endTime.setHours(18, 0, 0); // Set end time to 6:00 PM

  const timeSlots = [];

  while (startTime < endTime) {
    // Check if the current time has passed for this time slot
    if (
      (targetDate === currentTime.toDateString() && startTime > currentTime) ||
      targetDate !== currentTime.toDateString()
    ) {
      const hours = startTime.getHours();
      const minutes = startTime.getMinutes();

      // Convert to 12-hour format with AM/PM indicator
      const ampm = hours >= 12 ? "PM" : "AM";
      const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
      const formattedTime = `${formattedHours
        .toString()
        .padStart(2, "0")}:${minutes.toString().padStart(2, "0")} ${ampm}`;

      const timeSlotObj = {
        time: formattedTime,
        afterHours: hours >= 18,
        id: uuidV4(),
        // You can use your preferred way to generate an ID here
        // id: ...,
      };

      timeSlots.push(timeSlotObj);
    }

    startTime.setTime(startTime.getTime() + 15 * 60 * 1000); // Increment by 15 minutes
  }

  return timeSlots;
};

export const generateEmptySpans = (num) => {
  let spans = [];
  for (let i = 0; i < num; i += 1) {
    spans.push(<span key={uuidV4()}></span>);
  }
  return spans;
};
