import React from "react";
import styles from "./AboutMe.module.css";
import classNames from "classnames";
import profileImage from "./profileImage.JPG";
const AboutMe = () => {
  return (
    <div className={classNames(styles.AboutMe)}>
      <div
        className={classNames(
          styles.AboutMeContainer,
          "flex flex-col items-center justify-center h-screen p-8 space-y-6 border rounded-lg "
        )}
      >
        <div className={classNames(styles.AboutMeCenter)}>
          <img
            src={profileImage} // Placeholder image URL
            alt="Profile"
            className="w-40 h-60  border border-gray-300"
          />
          <div className="text-center">
            <h1 className="text-2xl font-bold">Ameli</h1>
            <p className="text-gray-600">Hair Stylist</p>
          </div>
          <p className="text-center">
            My name is Ameli, and I've been a hair stylist for 10 years. I'm
            truly passionate about hair and love making people feel beautiful.
            Alongside my thriving career, I'm a proud mother of 5 and spending
            quality time with my family is my greatest joy. I'm a naturally
            outgoing person, always excited to meet new people. I also have my
            own cooperation known as Queenelish Cooperation. Through Queenelish,
            we work diligently to empower the youth, guiding them toward
            brighter destinies while fostering accountability. I firmly believe
            that the future of the world rests in the hands of our youth, and
            I'm committed to contributing positively to their journeys.
          </p>
          <p className="text-center">
            My journey in the hair industry started about 12 years ago when I
            came to America. It was here that I met my mentor, who provided
            invaluable support and guidance, propelling me into the hair
            braiding industry. I started braiding to support my family and help
            my husband. Three years ago, I moved to Texas and established my own
            business, Destiny Hair Braiding, which has been an incredible
            journey.
          </p>
          <p className="text-center">
            But my story doesn't stop there. I am also the founder of Queenelish
            Cooperation, a remarkable initiative dedicated to nurturing the
            youth. At Queenelish, we work not only with the youth but also with
            widows, providing support for their general needs. Additionally, we
            extend our care to orphans, assisting them in finding loving
            families. Through Queenelish, we aim to create a better destination
            in life and promote accountability. I firmly hold the belief that
            the destiny of the world rests in the hands of our youth, and I am
            committed to shaping it positively.
          </p>
          <p className="text-center">
            Whether I'm crafting intricate braids or elegant updos, I'm driven
            by the belief that my work enhances not only appearances but also
            confidence and happiness. I thrive on forming connections with my
            clients, valuing each interaction as an opportunity to create beauty
            and make lasting impacts. I also believe in the power of the youth
            and their ability to shape the world's future. Alongside working
            with the youth, we also collaborate with widows to support their
            general needs and with orphans to help them find loving families.
          </p>
          <p className="text-center">
            Join me in this exciting journey. Let's explore the world of hair
            and beauty together!
          </p>
          <button>Book Now!</button>
        </div>
      </div>
    </div>
  );
};

export default AboutMe;

/*
Write me an about me that goes along the lines of 

" my name is ameli and i am a hair stylist. I have been doing hair for 10 years and i love it. I have a passion for hair and i love to make people feel beautiful. I am a mother of 5 and i love to spend time with my family. I am a very outgoing person and i love to meet new people, 
I came to america about 12 years ago and I there i met my mentor who thought about everything i need to get started int hair braiding industry
and ever seince then i have been doing hair. I first braied to support my family and and help my huisband in anyway possibeel, I move to texas 
about 3 years ago and startwroking from how and now im i was ablr to buuilt destiny hair brainding 
*/
