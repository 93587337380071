import React, { useState } from "react";
import "./CreateHair.css";
import { Input } from "@mui/material";
import Text from "./Text";
import MultiSelect from "./MultiSelect";
import CreateLength from "./CreateLength";
import PreviewProducts from "../Product/PreviewProducts";
import AddImages from "../AddImages/AddImages";
function CreateHair(props) {
  const [createProduct, setCreateProduct] = useState([]);
  const [createLength, setCreateLength] = useState([]);
  const [selectSection, setSelectSection] = useState([]);
  const [fileForImg, setFileForImg] = useState();
  const [previewImage, setPreviewImage] = useState();
  const [inputValue, setInputValue] = useState({
    tags: [],
    name: "",
    img: "",
    id: "",
    price: "",
    time: "",
    bringHair: false,
    downPayment: "",
    description: "string",
    length: [],
  });

  const [lengthInputValue, setLengthInputValue] = useState({
    size: "",
    time1: "",
    time2: "",
    price: "",
  });

  const inputChange = (e) => {
    setInputValue((prevInputValue) => ({
      ...prevInputValue,
      [e.target.name]: e.target.value,
    }));
  };

  const createProductHandler = () => {
    setCreateProduct({
      ...inputValue,
      id: Math.random(),
      ...createProduct,
      selectSection,
      tags: [...selectSection],
    });
  };

  const lengthInputValueChange = (e) => {
    setLengthInputValue((prevInputValue) => ({
      ...prevInputValue,
      [e.target.name]: e.target.value,
    }));
  };

  const addLengthHandler = () => {
    setCreateLength([...createLength, lengthInputValue]);
  };

  return (
    <div className="CreateHair">
      <div className="CreateHairInput">
        {/* <AddImages
          fileForImg={fileForImg}
          setFileForImg={setFileForImg}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
        /> */}
        <MultiSelect
          label={"tags"}
          setSelectSection={setSelectSection}
          selectSection={selectSection}
          inputChange={inputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Text
          label={"name"}
          inputChange={inputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Text
          label={"price"}
          inputChange={inputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Text
          label={"downPayment"}
          inputChange={inputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Text
          label={"time"}
          inputChange={inputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <Text
          label={"description"}
          inputChange={inputChange}
          inputValue={inputValue}
          setInputValue={setInputValue}
        />
        <div>
          <CreateLength
            lengthInputValueChange={lengthInputValueChange}
            lengthInputValue={lengthInputValue}
            addLengthHandler={addLengthHandler}
          />
        </div>
        <button onClick={createProductHandler}> add</button>
      </div>
      <div>
        <PreviewProducts
          inputValue={inputValue}
          fileForImg={fileForImg}
          setFileForImg={setFileForImg}
          previewImage={previewImage}
          setPreviewImage={setPreviewImage}
        />
      </div>
      {/* <div>name:{inputValue.name}</div>
      <div>price:{inputValue.price}</div>
      <div>downPayment{inputValue.downPayment}</div>
      <div>time{inputValue?.time}</div>
      <div>description{inputValue.description}</div> */}
    </div>
  );
}
// <img
//   boxSize="150px"
//   objectFit="cover"
//   src={previewImage}
//   alt="preview img"
// />;
export default CreateHair;
