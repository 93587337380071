import React, { useEffect, useRef } from "react";
import "./video.css";
import classNames from "classnames";
import { Link } from "react-router-dom";
import destinyhairNoBack from "./destinyLogoremovebg.png";

const VideoPlayer = () => {
  return (
    <div className="video">
      <div className={classNames("gif-container gif1")}></div>
      <div className={classNames("gif-container gif2")}>
        <div
          className={classNames(
            "flex  h-4/5 items-end justify-center flex-col space-y-4"
          )}
        >
          <img src={destinyhairNoBack} alt="" />
        </div>
      </div>
      <div className={classNames("gif-container gif3")}></div>
    </div>
  );
};

export default VideoPlayer;
