import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
function PoliciesModal({ open, setOpen }) {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 min-w-1/2">
                <div className="border pl-6">
                  <button onClick={() => setOpen(false)}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-600 hover:text-red-600 transition duration-300"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth={2}
                        d="M6 18L18 6M6 6l12 12"
                      />
                    </svg>
                  </button>
                </div>
                <div className="p-6">
                  <p className="mb-4">SMS Policy for Destiny Hair Braiding:</p>
                  <ol className="list-decimal pl-6">
                    <li>
                      Purpose: SMS messages will provide appointment updates and
                      essential information.
                    </li>
                    <li>Opt-In: Users must opt-in for SMS notifications.</li>
                    <li>Frequency: We'll send messages only when necessary.</li>
                    <li>
                      Content: Messages will be clear, focusing on appointments
                      and account info.
                    </li>
                    <li>
                      Privacy: User data is secure; we follow strict privacy
                      policies.
                    </li>
                    <li>Opt-Out: Users can easily unsubscribe at any time.</li>
                    <li>
                      Delivery: Timely delivery is our aim, but external factors
                      may affect it.
                    </li>
                    <li>
                      Feedback: Questions or concerns? Contact Destiny Hair
                      Braiding Support. By opting in, users agree to these
                      terms.
                    </li>
                  </ol>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}

export default PoliciesModal;
