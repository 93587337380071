import { createContext, useContext, useState, useEffect } from "react";

const CreateSuccessfulMessageContext = createContext(null);
export const useCreateSuccessfulMessageContext = () =>
  useContext(CreateSuccessfulMessageContext);

const SuccessfulMessageContext = ({ children }) => {
  const [successfulRedirect, setSuccessfulRedirect] = useState(false);
  return (
    <CreateSuccessfulMessageContext.Provider
      value={{
        successfulRedirect,
        setSuccessfulRedirect,
      }}
    >
      {children}
    </CreateSuccessfulMessageContext.Provider>
  );
};

export default SuccessfulMessageContext;
