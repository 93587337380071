import { Link } from "react-router-dom";
import styles from "./Products.module.css";

import { useState } from "react";
import classNames from "classnames";
// import hair from "./hair.png";
export default function Products({ name, price, id, time, image, hairType }) {
  return (
    // <div className={styles.products}>
    <div className={classNames(styles.card)}>
      <div className={styles.imageContainer}>
        <img src={image} alt="" className={styles.hairImage} />
      </div>
      <div className={styles.productsName}>
        <h1>{name}</h1>
      </div>
      {/* <div className={styles.productLine}></div> */}
      <div className={classNames(styles.price)}>
        <h1>${price}</h1>
      </div>
      <div>
        <Link to={`/checkout/${id}`} state={{ hairType }}>
          <button className={styles.button5}>Book</button>
        </Link>
      </div>
    </div>
    // </div>
  );
}
