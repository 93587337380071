import React, { useEffect, useState } from "react";
import { calender, generateTimeRanges, months } from "./calendarHelper.js";
import EmptyDays from "./EmptyDays.js";
import { v4 as uuidV4 } from "uuid";
import "./calendarStyles.css";
import { MdArrowForwardIos, MdOutlineArrowBackIos } from "react-icons/md";
import dayjs from "dayjs";
import { checkDayAvailability } from "../services/HairServices.js";
import SelectComponent from "../uiComponent/SelectComponent.jsx";
import classNames from "classnames";

const Calendar = ({
  setClickedSelectedDate,
  selectedDateInfo,
  setSelectedDateInfo,
  oneHair,
  selected,
  setSelected,
  loadingTime,
  setLoadingTime,
}) => {
  const [calendarInfo, setCalendarInfo] = useState(
    calender(dayjs().year(), dayjs().month())
  );
  // useEffect(() => {
  //   const c () => {};
  //   updatedCalender();
  // }, [calendarInfo, selected, selectedDateInfo]);

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const decreaseMonth = () => {
    setCalendarInfo((state) => {
      let year = state.monthNumber === 0 ? state.year - 1 : state.year;
      let month = year < state.year ? 11 : state.monthNumber - 1;
      return calender(year, month);
    });
  };

  const increaseMonth = () => {
    setCalendarInfo((state) => {
      let year = state.monthNumber === 11 ? state.year + 1 : state.year;
      let month = year > state.year ? 0 : state.monthNumber + 1;
      return calender(year, month);
    });
  };

  const dateToString = (day, month, year) => `${months[month]} ${day}, ${year}`;

  const selectDay = async (day, month, year, available) => {
    if (!available) {
      return;
    }
    setLoadingTime(true);
    const selectedDay = dateToString(day, month, year);

    const { daytime, nighttime } = await generateTimeRanges(selectedDay);
    const selectedDateInfo = {
      daytime,
      nighttime,
      date: selectedDay,
    };
    setLoadingTime(false);

    setSelectedDateInfo(selectedDateInfo);
    setClickedSelectedDate(selectedDay);
  };
  function isDateEqualToToday(dateString) {
    const givenDate = new Date(dateString);
    const currentDate = new Date();

    // Compare year, month, and day of the given date and current date
    return (
      givenDate.getFullYear() === currentDate.getFullYear() &&
      givenDate.getMonth() === currentDate.getMonth() &&
      givenDate.getDate() === currentDate.getDate()
    );
  }

  return (
    <div>
      <SelectComponent
        hairInfo={oneHair.length}
        selected={selected}
        setSelected={setSelected}
      />
      <div className="overflow-hidden rounded-lg bg-white shadow mt-3">
        <div className="px-4 py-5 sm:p-6">
          <h1 className="w-[19rem]">{oneHair?.description}</h1>
        </div>
      </div>
      <section className=" flex darkOverlay flex-col mt-4">
        <div className="calendarWrapper">
          <div className="calendarContainer">
            <section>
              <div className="monthAndYear">
                <div>
                  {calendarInfo.month} {calendarInfo.year}
                </div>
                <div className="nextButtons">
                  {calendarInfo.calender[0].available ? (
                    <MdOutlineArrowBackIos
                      onClick={decreaseMonth}
                      className="arrowButton"
                    />
                  ) : (
                    <MdOutlineArrowBackIos className="arrowButtonDisabled" />
                  )}

                  <MdArrowForwardIos
                    onClick={increaseMonth}
                    className="arrowButton"
                  />
                </div>
              </div>
              <div className="calendarGrid">
                {days.map((day) => (
                  <span className="calendarSlot" key={uuidV4()}>
                    {day}
                  </span>
                ))}
                <EmptyDays calenderInfo={calendarInfo} days={days} />
                {calendarInfo.calender.map(({ day, available, year }) => (
                  <span
                    key={uuidV4()}
                    className={classNames({
                      today: isDateEqualToToday(
                        dateToString(day, calendarInfo.monthNumber, year)
                      ),
                      unavailableDay: !available,
                      "availableDay selected":
                        selectedDateInfo &&
                        selectedDateInfo.date ===
                          dateToString(day, calendarInfo.monthNumber, year),
                      availableDay:
                        available &&
                        (!selectedDateInfo ||
                          selectedDateInfo.date !==
                            dateToString(day, calendarInfo.monthNumber, year)),
                    })}
                    onClick={() =>
                      selectDay(day, calendarInfo.monthNumber, year, available)
                    }
                  >
                    {day}
                  </span>
                ))}
              </div>
            </section>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Calendar;

// import {
//   add,
//   eachDayOfInterval,
//   endOfMonth,
//   format,
//   getDay,
//   isEqual,
//   isSameDay,
//   isSameMonth,
//   isToday,
//   parse,
//   parseISO,
//   startOfToday,
// } from "date-fns";
// import { Fragment, useState } from "react";
// import { Menu, Transition } from "@headlessui/react";
// import { AiOutlineRight } from "react-icons/ai";
// import { AiOutlineLeft } from "react-icons/ai";
// import { calender, generateTimeRanges, months } from "./calendarHelper.js";
// import SelectComponent from "../uiComponent/SelectComponent";

// function classNames(...classes) {
//   return classes.filter(Boolean).join(" ");
// }
// {
//   /* <ChevronRightIcon class="h-6 w-6 text-gray-500" />; */
// }
// const Calendar = ({
//   setClickedSelectedDate,
//   selectedDateInfo,
//   setSelectedDateInfo,
//   oneHair,
//   selected,
//   setSelected,
//   loadingTime,
//   setLoadingTime,
// }) => {
//   let today = startOfToday();
//   let [selectedDay, setSelectedDay] = useState(today);
//   let [currentMonth, setCurrentMonth] = useState(format(today, "MMM-yyyy"));
//   let firstDayCurrentMonth = parse(currentMonth, "MMM-yyyy", new Date());

//   let days = eachDayOfInterval({
//     start: firstDayCurrentMonth,
//     end: endOfMonth(firstDayCurrentMonth),
//   });

//   function previousMonth() {
//     let firstDayNextMonth = add(firstDayCurrentMonth, { months: -1 });
//     setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
//   }

//   function nextMonth() {
//     let firstDayNextMonth = add(firstDayCurrentMonth, { months: 1 });
//     setCurrentMonth(format(firstDayNextMonth, "MMM-yyyy"));
//   }

//   // const dateToString = (day, month, year) => `${months[month]} ${day}, ${year}`;
//   const selectedDayFunc = async (day) => {
//     // if the selected day is less then today and less then yesterday return
//     if (day < today || day === isToday) {
//       return;
//     }
//     // setLoadingTime(true);
//     // const selectedDay = dateToString(day, month, year);

//     // const { daytime, nighttime } = await generateTimeRanges(selectedDay);
//     // const selectedDateInfo = {
//     //   daytime,
//     //   nighttime,
//     //   date: selectedDay,
//     // };
//     setSelectedDay(day);

//     // selectedDay
//     // setLoadingTime(false);

//     // setSelectedDateInfo(selectedDateInfo);
//     // setClickedSelectedDate(selectedDay);
//     // setSelectedDay(day);
//   };
//   return (
//     <div className="pt-16">
//       <SelectComponent
//         hairInfo={oneHair.length}
//         selected={selected}
//         setSelected={setSelected}
//       />
//       <section className=" flex darkOverlay flex-col mt-4">
//         <div className="calendarWrapper">
//           <div className="calendarContainer">
//             <section>
//               <h2 className="flex-auto font-semibold text-gray-900">
//                 {format(firstDayCurrentMonth, "MMMM yyyy")}
//               </h2>
//               <button
//                 type="button"
//                 disabled={isSameMonth(today, firstDayCurrentMonth)}
//                 onClick={previousMonth}
//                 className="-my-1.5 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
//               >
//                 <span className="sr-only">Previous month</span>
//                 <AiOutlineLeft className="w-5 h-5" aria-hidden="true" />
//               </button>
//               <button
//                 onClick={nextMonth}
//                 type="button"
//                 className="-my-1.5 -mr-1.5 ml-2 flex flex-none items-center justify-center p-1.5 text-gray-400 hover:text-gray-500"
//               >
//                 <span className="sr-only">Next month</span>

//                 <AiOutlineRight className="w-5 h-5" aria-hidden="true" />
//               </button>
//             </section>
//             <div className="grid grid-cols-7 mt-10 text-xs leading-6 text-center text-gray-500">
//               <div>S</div>
//               <div>M</div>
//               <div>T</div>
//               <div>W</div>
//               <div>T</div>
//               <div>F</div>
//               <div>S</div>
//             </div>
//             <div className="grid grid-cols-7 mt-2 text-sm">
//               {days.map((day, dayIdx) => (
//                 <div
//                   key={day.toString()}
//                   className={classNames(
//                     dayIdx === 0 && colStartClasses[getDay(day)],
//                     "py-1.5"
//                   )}
//                 >
//                   <button
//                     type="button"
//                     onClick={() => selectedDayFunc(day)}
//                     className={classNames(
//                       isEqual(day, selectedDay) && "text-white",
//                       !isEqual(day, selectedDay) &&
//                         isToday(day) &&
//                         "text-red-500",
//                       !isEqual(day, selectedDay) &&
//                         !isToday(day) &&
//                         isSameMonth(day, firstDayCurrentMonth) &&
//                         "text-gray-900",
//                       !isEqual(day, selectedDay) &&
//                         !isToday(day) &&
//                         !isSameMonth(day, firstDayCurrentMonth) &&
//                         "text-gray-400",
//                       isEqual(day, selectedDay) && isToday(day) && "bg-red-500",
//                       isEqual(day, selectedDay) &&
//                         !isToday(day) &&
//                         "bg-gray-900",
//                       !isEqual(day, selectedDay) && "hover:bg-gray-200",
//                       (isEqual(day, selectedDay) || isToday(day)) &&
//                         "font-semibold",
//                       "mx-auto flex h-8 w-8 items-center justify-center rounded-full"
//                     )}
//                   >
//                     <time dateTime={format(day, "yyyy-MM-dd")}>
//                       {format(day, "d")}
//                     </time>
//                   </button>
//                 </div>
//               ))}
//             </div>
//           </div>
//         </div>
//       </section>
//     </div>
//   );
// };

// let colStartClasses = [
//   "",
//   "col-start-2",
//   "col-start-3",
//   "col-start-4",
//   "col-start-5",
//   "col-start-6",
//   "col-start-7",
// ];
// export default Calendar;
