import React, { useEffect, useState } from "react";
import "./CheckoutPage.css";
import { useParams } from "react-router-dom";
import { getOneHair } from "../services/HairServices";
import SimpleSnackbar from "./SimpleSnackbar";
import classNames from "classnames";
import { CheckOutTabs } from "./CheckOutTabs";

function CheckoutPage({ successfulRedirect, setSuccessfulRedirect }) {
  const [openSnackBar, setOpenSnackBar] = useState({ open: false, text: "" });
  const [oneHair, setOneHair] = useState({});
  const [selected, setSelected] = useState(null);
  const { id } = useParams();

  useEffect(
    () => {
      const fetchData = async () => {
        let data = await getOneHair(id);
        setOneHair(data);
        setSelected(data.length[0]);
      };
      fetchData();
    },
    [id],
    selected
  );
  return (
    <div className={classNames("CheckoutPage ")}>
      <div className="p-5 ">
        <div className="flex flex-col gap-5">
          <CheckOutTabs
            selected={selected}
            setSelected={setSelected}
            hairDataAPi={oneHair}
            openSnackBar={openSnackBar}
            setOpenSnackBar={setOpenSnackBar}
            setSuccessfulRedirect={setSuccessfulRedirect}
            successfulRedirect={successfulRedirect}
          />
        </div>
      </div>
      <div>
        <SimpleSnackbar
          openSnackBar={openSnackBar}
          setOpenSnackBar={setOpenSnackBar}
        />
      </div>
    </div>
  );
}

export default CheckoutPage;
