import React from "react";
import displayImg from "./m.gif";
import "./EntryPage.css";
import { Link } from "react-router-dom";
function EntryPage(props) {
  return (
    <>
      <div className="entryImg relative isolate  pt-14x"></div>
      <div class="highlight">
        <div className="highlightTextBox">
          <Link to="/book">
            <h1>WELCOME TO</h1>
            <h1> Destiny African Hair Braiding </h1> <br />
            <h1>Book Now</h1>
          </Link>
        </div>
      </div>
    </>
  );
}

export default EntryPage;
